$med-grey: #22293B;

.logo {
  // width: 120px;
  // height: 31px;
  float: left;
  margin: 16px 24px 16px 0;
  height: 32px;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.header {
  position: "fixed";
  z-index: 1000 !important;
  width: "100%";
}

.user-profile-svg {
  max-height: 30px;
  max-width: 30px;
  margin: auto;
  cursor: pointer;
}

.ant-menu-item-icon svg {
  color: rgba($color: #ffffff, $alpha: 0.65);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-title .anticon {
  font-size: 24px;
}

// .pagination-row {
//   padding-right: 1em;
// }

// .sidebar {
//   height: 100vh;
// }

.header .anticon svg {
  color: #fff;
}

.ant-drawer .anticon svg {
  color: #ddd;
}

.ant-drawer .anticon svg {
  color: #ddd;
}

.header button.ant-btn.ant-btn-primary.ant-btn-icon-only.menu {
  position: absolute;
  left: 5px;
  top: 11px;
  background: $med-grey;
  border-color: $med-grey;
}

#menu-burger {
  position: absolute;
  left: 5px;
  top: 11px;
}

.form-control-col-3 {
  display: none;
}

@media (max-width: 768px) {
  .otpInput {
    height: 36px;
    width: 36px;
  }

  .form-control-row-2 {
    display: none;
  }

  .form-control-col-3 {
    display: block !important;
  }
}

@media (max-width: 992px) {
  #right-corner-menu {
    visibility: hidden;
  }
}

.ant-col.ant-col-sm-24.ant-col-md-11 {
  border: 1px solid #001529;
  padding: 8px;
  border-radius: 5px;
  margin: 10px 0 5px 0;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
  background-color: transparent !important;
  opacity: 1 !important;
}

// Dropdown Navbar 
.ant-menu-dark.ant-menu-submenu-popup {
  position: fixed !important;
  top: 57px !important;
  z-index: 1000;
}

.ant-menu-title-content .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #fff;
}

.ant-menu-title-content .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #fff;
}

.ant-menu-title-content .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  width: 130px;
  color: #fff;
}